import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, isLoggedOut: boolean }> {
    public state = {
        isOpen: false,
        isLoggedOut: false
    };

    public render() {
        if (this.state.isLoggedOut) {
            // window.location.href = "/Identity/Account/Login";
            return (<Redirect
                to="/login"
            />)
        }
        return (
            <header id="header" className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#"><img src="assets/img/datadot-logo.png" alt="" className="img-fluid" /></a>
                <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" />
                <div className="navbar-nav">
                    <div className="nav-item text-nowrap">
                        <a className="nav-link px-3" onClick={(e) => { this.logOut() }}>Sign out</a>
                    </div>
                </div>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private logOut = () => {
        fetch(`/api/logout`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return (response.json() as Promise<any>);
                }
                return response.json() as Promise<any>;
            })
            .then(data => {
                console.log("logout", data);
                if (data.success) {
                    this.setState({
                        isLoggedOut: true
                    });
                }
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    }
}
