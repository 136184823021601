import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as SearchStore from '../store/Search';
import * as ContactStore from '../store/Contact';
import countryList from 'react-select-country-list';
import { Redirect } from 'react-router-dom';

// At runtime, Redux will merge together...
type VehicleProps = SearchStore.SearchState &
    typeof ContactStore.actionCreators & RouteComponentProps<{}>;

interface VehicleState {
    hasHitContact: boolean,
}

class VehicleView extends React.PureComponent<VehicleProps, VehicleState> {

    state: VehicleState = {
        hasHitContact:false
    }

    submitContact = () => {
        const contact: ContactStore.ContactModel = { message: 'test', email: '', phone: '' };
        if (this.props.vehicle!.isVIN)
            contact.VIN = this.props.vehicle!.vin;
        if (!this.props.vehicle!.isVIN)
            contact.UID = this.props.vehicle!.ident;
        this.props.setupContact(contact);

        this.setState((prevState) => {
            return { hasHitContact: true };
        });
    }

    public render() {
        if (this.props.vehicle === undefined || this.props.vehicle === null)
            return (<div>No Vehicle</div>)

        var countryCode = this.props.vehicle === undefined || this.props.vehicle === null ? 'AU' : this.props.vehicle.countryOfOriginCode;
        var country = countryList().getData().find(x => x.value === countryCode);

        if (this.state.hasHitContact) {
            return (<Redirect
                to="/contact"
            />);
        }

        return (
            <React.Fragment>
                {/* ======= Doctors Section ======= */}
                <section id="doctors" className="doctors">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="pic halignhelper">
                                    <div>
                                        <img src={this.props.vehicle.imageUrl} className="img-fluid" alt="" />
                                        Vehicle and microdot image for illustration purposes only
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="member d-flex align-items-start">
                                    <div className="member-info">
                                        <img src="assets/img/vehicle-protected-image.png" className="img-fluid mb-3" />
                                        <label>Registered Vehicle</label>
                                        <div>{this.props.vehicle.make} {this.props.vehicle.model}</div>
                                        <label>Vehicle Type</label>
                                        <div>{this.props.vehicle.type}</div>
                                        <label>Year of Manufacture</label>
                                        <div>{this.props.vehicle.year}</div>
                                        <label>Vehicle Primary Colour</label>
                                        <div>{this.props.vehicle.colour}</div>
                                        <label>VIN (Vehicle Identification Number)</label>
                                        <div>{this.props.vehicle.vin}</div>
                                        <label>dDotDNA - UID (Unique Identification Code)</label>
                                        <div>{this.props.vehicle.ident}</div>
                                        <label>UID Fitment Country of Origin</label>
                                        <div>{country != null ? country.label : ''}</div>
                                        <label>Date of Registration</label>
                                        <div>{this.props.vehicle.dateOfRegistration}</div>
                                        <h4 className="mt-3">Law Enforcement</h4>
                                        If you require further information regarding the VIN or UID details for this vehicle, please submit a request via the 'CONTACT' button below..
                                        <div className="buttonbox">
                                            <button type="button" onClick={() => { this.submitContact(); }}>CONTACT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Doctors Section */}
            </React.Fragment>
        );
    }

   
}

export default connect(
    (state: ApplicationState) => state.search, // Selects which state properties are merged into the component's props,
    ContactStore.actionCreators
)(VehicleView as any);
