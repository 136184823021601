import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as ContactStore from '../store/Contact';
import ReCAPTCHA from "react-google-recaptcha";
import { closeMobileNav } from '../store/common';
import { getCopy } from '../store/copyservice';

// At runtime, Redux will merge together...
type ContactProps = ContactStore.ContactState &
    typeof ContactStore.actionCreators &
    RouteComponentProps<{}>;

interface FormValidation {
    formSubmitAttempted: boolean,
    formIsValid: boolean,
    errors: Map<string,string>
}

interface ContactViewState {
    contact: ContactStore.ContactModel,
    captcha: string,
    validation: FormValidation,
    copy: any
}

class Contact extends React.PureComponent<ContactProps, ContactViewState> {

    state: ContactViewState = {
        contact: { message: '', email: '', phone: '' },
        validation: { formIsValid: false, formSubmitAttempted: false, errors: new Map<string, string>() },
        captcha: "",
        copy: null
    }

    componentWillMount() {
        console.log("raaar!")
        closeMobileNav();
        if (this.props.contact) {
            const contact = this.props.contact;
            contact.name = "";
            contact.phone = "";
            contact.badgeNumber = "";
            contact.department = "";
            contact.address = "";
            contact.message = "";
            contact.email = "";
            contact.phone = "";
            this.setState((prevState) => {
                return { contact, validation: prevState.validation, captcha: prevState.captcha }
            });
        }
        getCopy('Contact').then(c => {
            this.setState((prevState) => {
                return { copy: c };
            });
        });
    }

    runValidation = (state: ContactViewState, contact: ContactStore.ContactModel): FormValidation => {
        let validation: FormValidation = { formIsValid: state.validation.formIsValid, formSubmitAttempted: state.validation.formSubmitAttempted, errors: new Map<string, string>() }
        const police = this.state.contact.VIN || this.state.contact.UID;
        if (contact.message == "") {
            validation.errors.set("message", this.state.copy?.Contact_Validation_Message);
        }
        if (contact.email == "") {
            validation.errors.set("email", this.state.copy?.Contact_Validation_Email); 
        }
        if (police) {
            if (contact.name == "") {
                validation.errors.set("name", this.state.copy?.Contact_Validation_Name);
            }
            if (contact.phone == "") {
                validation.errors.set("phone", this.state.copy?.Contact_Validation_Phone);
            }
            if (contact.badgeNumber == "") {
                validation.errors.set("badgeNumber", this.state.copy?.Contact_Validation_BadgeNumber);
            }
            if (contact.department == "") {
                validation.errors.set("department", this.state.copy?.Contact_Validation_Department);
            }
            if (contact.address == "") {
                validation.errors.set("address", this.state.copy?.Contact_Validation_Address);
            }
        }

        validation.formIsValid = validation.errors.size === 0;
        return validation;
    }

    onChange = (id:string,value:string | null) => {
        // const newValue = e.target.value;
        // const id = e.target.id;
        // console.log("e.target",e.target);
        var newValue = value || "";

        this.setState((prevState) => {

            let contact: ContactStore.ContactModel = {
                message: id == "message" ? newValue : prevState.contact.message,
                email: id == "email" ? newValue : prevState.contact.email,
                name: id == "name" ? newValue : prevState.contact.name,
                phone: id == "phone" ? newValue : prevState.contact.phone,
                badgeNumber: id == "badgeNumber" ? newValue : prevState.contact.badgeNumber,
                department: id == "department" ? newValue : prevState.contact.department,
                address: id == "address" ? newValue : prevState.contact.address,
                VIN: prevState.contact.VIN,
                UID: prevState.contact.UID,
            }

            let validation = this.runValidation(prevState, contact);

            return { contact, validation, captcha: prevState.captcha };
        });
    }

    onChangeSelect = (event: any) => {
        const value = event.value;
        console.log(value);
        this.onChange("countryCode", value);
        //setSelectedOption(value);
         //"countryCode", e === null || e === undefined ? '' : e.value as CountryData)
    };

    submitForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log("test");
        let validation = this.runValidation(this.state, this.state.contact);
        
        this.setState((prevState) => {
            validation.formSubmitAttempted = true;
            return {validation}
        });

        if (validation.formIsValid) {
            console.log("would've fired");
            this.props.sendContact(this.state.contact, this.state.captcha);
        }
        
    }

    public onCapChange(value: any) {
        console.log("Captcha value:", value);
        this.setState((prevState) => {
            return { captcha: value };
        });
    }

    public render() {

        var validation = (field: string) => {
            if (!this.state.validation.formSubmitAttempted)
                return null;
            var err = this.state.validation.errors.get(field);
            if(err != undefined)
                return (<div className="validate">{err}</div>)
            if (this.props.contactResult !== undefined && this.props.contactResult.modelState != undefined && this.props.contactResult.modelState[field as keyof ContactStore.ModelState] != undefined) {
                return (<div className="validate">{this.props.contactResult.modelState[field as keyof ContactStore.ModelState]}</div>)
            }
            return null
        }

        const customStyles = {
            option: (provided:any, state:any) => ({
                ...provided,
                borderBottom: '1px dotted pink',
                color: state.isSelected ? 'red' : 'blue',
                padding: 20,
            })
        }

        // console.log(this.props.contactResult);
        if (this.props.contactResult !== undefined && this.props.contactResult.success) {
            return (
                <React.Fragment>
                    {/* ======= Why Us Section ======= */}
                    <section id="why-us" className="why-us">
                        <div className="container appointment" id="appointment">
                            <div className="row">
                                <div className="col-lg-4 offset-lg-4 d-flex align-items-stretch">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="content">
                                                <form className="php-email-form">
                                                    <h3>{this.state.copy?.Contact_Sent_Heading}</h3>
                                                    <i className="bi bi-check-circle-fill"></i> { this.state.copy?.Contact_Sent_Message } 
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Why Us Section */}
                </React.Fragment>
            );
        }

        const police = this.state.contact.VIN || this.state.contact.UID;

        return (
            <React.Fragment>
                {/* ======= Contact Section ======= */}
                <section id="contact" className="contact">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.state.copy?.Contact_Sent_Heading }</h2>
                            {police ?
                                <p>{this.state.copy?.Contact_Instructions_Police}</p>
                                :
                                <p></p>
                            }
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="address">
                                        <i className="bi bi-geo-alt" />
                                        <h4>{this.state.copy?.Contact_Details_Location}:</h4>
                                        <p>8 Ethel Avenue, Brookvale NSW 2100, Australia</p>
                                    </div>
                                    <div className="email">
                                        <i className="bi bi-envelope" />
                                        <h4>{this.state.copy?.Contact_Details_Email }:</h4>
                                        <p><a href="info@DataDotDNA.com">info@DataDotDNA.com</a></p>
                                    </div>
                                    <div className="phone">
                                        <i className="bi bi-phone" />
                                        <h4>{this.state.copy?.Contact_Details_Phone}:</h4>
                                        <p>+61 2 8977 4900 / 1300 300 829</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 mt-5 mt-lg-0">
                                <form className="php-email-form">
                                    {police ?
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <input type="text" name="name" id="name" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Police_Name} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                            {validation("name")}
                                        </div>
                                        <div className="col-md-6 form-group">
                                                <input type="text" name="badgeNumber" id="badgeNumber" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Police_BadgeNumber} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                            {validation("badgeNumber")}
                                        </div>
                                        <div className="col-md-6 form-group">
                                                <input type="text" name="department" id="department" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Police_Department} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                            {validation("department")}
                                        </div>
                                        <div className="col-md-6 form-group">
                                                <input type="text" name="address" id="address" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Police_Address} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                            {validation("address")}
                                        </div>
                                        <div className="col-md-6 form-group">
                                                <input type="text" name="phone" id="phone" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Police_Phone} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                            {validation("phone")}
                                        </div>
                                        <div className="col-md-6 form-group">
                                                <input type="text" name="email" id="email" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Police_Email} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                            {validation("email")}
                                        </div>
                                        <div className="col-md-12 form-group">
                                                <textarea name="message" id="message" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Police_Message} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                            {validation("message")}
                                        </div>
                                    </div>
                                        :
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <input type="text" name="email" id="email" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Email} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                {validation("email")}
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <input type="text" name="phone" id="phone" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Phone} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                {validation("phone")}
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <textarea name="message" id="message" className="form-control" placeholder={this.state.copy?.Contact_Placeholder_Message} onChange={(e) => this.onChange(e.target.id, e.target.value)} />
                                                {validation("message")}
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="row">
                                            <ReCAPTCHA
                                                sitekey="6LcOyUMhAAAAAKhVvP3bZ1hKmumd7v2gxr1kDTgo"
                                                onChange={(val) => this.onCapChange(val)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        {this.props.isLoading &&
                                            <div className="loading">{this.state.copy?.Common_Loading}</div>
                                        }
                                        <div className="error-message" />
                                        <div className="sent-message"></div>
                                    </div>
                                    <div className="text-center"><button type="button" onClick={(e) => { this.submitForm(e) }}>{this.state.copy?.Contact_Button}</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>{/* End Contact Section */}
            </React.Fragment>
        );
    }

   
}

export default connect(
    (state: ApplicationState) => state.contact, // Selects which state properties are merged into the component's props
    ContactStore.actionCreators
)(Contact as any);
