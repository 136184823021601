// import 'bootstrap/dist/css/bootstrap.css';
import './custom.css'

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import NavMenu from './NavMenu';
import SideMenu from './SideMenu';

export default (props: { children?: React.ReactNode }) => {

    const [isAuthed, setAuth] = useState(true);

    useEffect(() => {
        fetch(`/api/auth`, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return (response.json() as Promise<any>);
                }
                return response.json() as Promise<any>;
            })
            .then(data => {
                console.log("auth", data);
                setAuth(data.success);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    });

    if (!isAuthed) {
        // window.location.href = "/Identity/Account/Login";
        return (<Redirect
            to="/login"
        />)
    }
    return (
        <React.Fragment>
            <NavMenu />
            <Container>
                <Row>
                    <SideMenu />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        {props.children}
                    </main>
                </Row>
            </Container>
        </React.Fragment>
    );
}
